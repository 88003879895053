import React, { useCallback, useEffect, useState } from 'react';
import { FaArrowRightLong } from "react-icons/fa6";
import Dropdown from './Dropdown';
import { FaStar } from "react-icons/fa";
import filterOptions from './filterOptions';
import { CiFilter } from "react-icons/ci";

const starCounts = [5, 4, 3, 2, 1];

const isScreenBiggerThan640px = () => {
  return window.matchMedia('(min-width: 640px)').matches;
};

const Filters = ({ handleQuery }) => {
  const [categories, setCategories] = useState([]);
  const [trainingTypes, setTrainingTypes] = useState([]);
  const [courseForms, setCourseForms] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [rating, setRating] = useState([]);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [comparePrices, setComparePrices] = useState(false);
  const { trainingMode, formOfKnowledge, typeOfContent, contentCategory } = filterOptions;

  const isSmallScr = !isScreenBiggerThan640px();

  const [shownOptions, setShownOptions] = useState(isSmallScr);

  const handleShowOptions = () => {
    setShownOptions(!shownOptions);
  }

  const handleFilterChange = (filterType, values) => {
    switch (filterType) {
      case 'categories':
        setCategories(values);
        break;
      case 'trainingTypes':
        setTrainingTypes(values);
        break;
      case 'courseForms':
        setCourseForms(values);
        break;
      case 'contentTypes':
        setContentTypes(values);
        break;
      case 'rating':
        setRating(values);
        break;
      default:
        break;
    }
  };

  const buildSearchCriteria = useCallback(() => {
    const criteria = [];

    if (categories.length > 0) criteria.push(`contentCategory:{${categories.join(',')}}`);
    if (trainingTypes.length > 0) criteria.push(`typeOfContentList:{${trainingTypes.join(',')}}`);
    if (courseForms.length > 0) criteria.push(`formOfKnowledge:{${courseForms.join(',')}}`);
    if (contentTypes.length > 0) criteria.push(`trainingMode:{${contentTypes.join(',')}}`);
    if (rating.length > 0) criteria.push(`ratingOnGoogle:>=${Math.max(...rating)}`);
    // if (rating.length > 0) criteria.push(`ratingOnGoogle:<=${highRate}`);
    if (minPrice) criteria.push(`minPrice:>=${minPrice}`);
    if (maxPrice) criteria.push(`maxPrice:<=${maxPrice}`);

    return criteria.join(';');
  }, [categories, courseForms, contentTypes, minPrice, maxPrice, rating, trainingTypes]);

  const fetchCourses = useCallback(async () => {
    const searchCriteria = buildSearchCriteria();
    try {
      handleQuery(searchCriteria);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  }, [buildSearchCriteria, handleQuery]);

  const checkPrices = useCallback(() => {
    const min = parseFloat(minPrice);
    const max = parseFloat(maxPrice);
  
    if (isNaN(min) || isNaN(max)) {
      setComparePrices(false);
      return;
    }
  
    if (max >= min) {
      setComparePrices(false);
    } else {
      setComparePrices(true);
    }
  }, [minPrice, maxPrice]);
  
  useEffect(() => {
    fetchCourses();
  }, [categories, trainingTypes, courseForms, contentTypes, rating, minPrice, maxPrice, fetchCourses]);

  useEffect(() => {
    checkPrices();
  }, [minPrice, maxPrice, checkPrices])

  return (
    <div className='flex flex-col justify-between border-2 border-maroon rounded-lg md:min-h-[95vh] mb-4 md:mb-0 w-full px-6 py-4'>
      <div>
        <div className='flex items-center justify-center gap-4'>
          <div
            className="px-6 py-2 h-12 bg-maroon text-lg text-white rounded-md cursor-pointer flex gap-4 items-center justify-center"
            onClick={fetchCourses}
          >
            <p>Pełna oferta</p>
            <FaArrowRightLong />
          </div>
          {isSmallScr && (
            <div onClick={() => handleShowOptions()}>
              <CiFilter className='h-8 w-8 text-[#00000077]' />
            </div>
          )}
        </div>

        {!shownOptions && (
          <div className='mt-2'>
            <Dropdown
              title="Kategorie"
              options={contentCategory}
              onChange={(values) => handleFilterChange('categories', values)}
            />
            <Dropdown
              title="Typ szkoleń"
              options={typeOfContent}
              onChange={(values) => handleFilterChange('trainingTypes', values)}
            />
            <Dropdown
              title="Forma szkoleń"
              options={trainingMode}
              onChange={(values) => handleFilterChange('courseForms', values)}
            />
            <Dropdown
              title="Rodzaj treści"
              options={formOfKnowledge}
              onChange={(values) => handleFilterChange('contentTypes', values)}
            />
            <Dropdown
              title="Ocena kursu"
              options={starCounts.map((count, index) => (
                {
                  name: (
                    <div key={count} className='flex gap-1'>
                      {Array.from({ length: count }).map((_, starIndex) => (
                        <FaStar key={starIndex} className='text-bronze' />
                      ))}
                    </div>
                  ), id: count
                }
              ))}
              onChange={(values) => handleFilterChange('rating', values)}
            />
            <div>
              <h3 className='text-xl'>Cena</h3>
              <div className='flex gap-4'>
                <input
                  type="number"
                  className='w-2/5 bg-transparent border-2 border-bronze h-8 rounded p-2 focus:border-maroon focus:outline-none text-sm'
                  placeholder='od PLN'
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                />
                <input
                  type="number"
                  className='w-2/5 bg-transparent border-2 border-bronze h-8 rounded p-2 focus:border-maroon focus:outline-none text-sm'
                  placeholder='do PLN'
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                />
              </div>
              {comparePrices && (
                <p className='text-red-500 font-normal mt-1 text-xs'>Cena maksymalna musi być nisza od minimalnej</p>
              )}
            </div>
          </div>
        )}
      </div>
      {!shownOptions && (
        <div
          className="px-6 mt-4 py-2 h-12 border-2 border-maroon text-maroon text-lg rounded-md cursor-pointer flex gap-4 items-center justify-center"
          onClick={() => {
            setCategories([]);
            setTrainingTypes([]);
            setCourseForms([]);
            setContentTypes([]);
            setRating([]);
            setMinPrice('');
            setMaxPrice('');
          }}
        >
          Wyczyść filtry
        </div>
      )}
    </div>
  );
}

export default Filters;
