import React, { useState, useEffect } from 'react';
import SignUpBG from '../img/SignUpBG.png';
import { FaArrowRightLong } from "react-icons/fa6";
import { Link, useParams } from 'react-router-dom';
import { registerUser, createEmptyProfile } from '../api/api';
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";


const SignUp = () => {
  const { profileId: paramProfileId } = useParams();
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [profileId, setProfileId] = useState(paramProfileId || '');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [privacy, setPrivacy] = useState(false);

  const togglePrivacy = () => {
    setPrivacy(!privacy);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const fetchProfileId = async () => {
      if (!profileId) {
        try {
          const response = await createEmptyProfile();
          setProfileId(response.id);
        } catch (error) {
          setErrorMessage('Failed to create a profile. Please try again.');
        }
      }
    };
    fetchProfileId();
  }, [profileId]);

  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setUserPassword(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    // const re = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.{8,})/;
    const re = /^[A-Za-z0-9!@#$%^&*()_+=?<>{}\[\]-]{10,}$/;
    return re.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(userEmail)) {
      setErrorMessage('Invalid email address');
      return;
    }
    if (!validatePassword(userPassword)) {
      setErrorMessage('Password must be at least 8 characters long, contain one uppercase letter, one special character, and one number');
      return;
    }
    if (!privacy) {
      setErrorMessage('Musisz wyrazić zgodę na przetwarzanie danych osobowych, żeby założyć konto.');
      return;
    }
    setErrorMessage('');

    const dataToSubmit = {
      email: userEmail,
      pass: userPassword,
      profileId
    };

    try {
      await registerUser(dataToSubmit);
      console.log('Form submitted successfully');
      setConfirmEmail(true);
    } catch (error) {
      console.error('Registration failed:', error);
      setErrorMessage('Registration failed. Please try again.');
    }
  };

  return (
    <div className='flex w-full full-height-minus-5rem bg-[#0B0B0D] relative'>
      <div
        className='w-[220%] sm:w-[150%] lg:w-full full-height-minus-5rem bg-contain bg-no-repeat bg-gradient-to-br from-lime-500 via-neutral-700 to-stone-100 absolute left-[-170%] sm:left-[-105%] lg:left-0'
        style={{
          backgroundImage: `url(${SignUpBG})`,
        }}
      ></div>
      <div className='w-[100%] lg:w-[50%] h-full flex flex-col justify-center items-center bg-gradient-to-l from-[#0B0B0D] from-70% md:from-80% lg:90% to-transparent to-100% sm:to-100% ml-auto z-10 pl-[7vw] sm:pl-[10vw] lg:pl-6'>
        {confirmEmail ? (
          <div className='w-full pl-4 pr-4 sm:pl-10 sm:pr-14 flex flex-col justify-between h-3/4 py-10 mt-auto'>
            <div className='w-3/4'>
              <div className='w-1/3 border-t-2 border-bronze'></div>
              <h2 className='text-white text-3xl py-4 font-light'>Potwierdź założenie <span className='text-bronze'>konta</span>!</h2>
              <p className='text-white mb-6 font-light'>
                Zaczekaj na maila, który za chwilę pojawi się na Twojej skrzynce i potwierdź założenie konta, aby dokończyć rejestrację.
              </p>

            </div>
            <div className='text-white mt-4 text-sm flex flex-col gap-1 items-end font-extralight'>
              <p className='w-max'>Nie otrzymałeś wiadomości potwierdzającej rejestrację? </p>
              <Link
                // to="/register"
                className='text-bronze underline pointer w-max'
              >
                Wyślij ponownie wiadomość.
              </Link>
            </div>
          </div>
        ) : (
          <div className='w-full pl-4 pr-4 sm:pl-10 sm:pr-14 flex flex-col justify-between h-3/4 py-10 mt-auto'>
            <div>
              <div className='w-1/3 border-t-4 lg:border-t-2 border-bronze'></div>
              <h2 className='text-white text-5xl lg:text-3xl py-4 font-light'>Zarejestruj się w ProRok</h2>
              <p className='text-white mb-6 text-xl lg:text-md font-light'>Uzupełnij swoje dane</p>
              {errorMessage && <p className='text-red-500'>{errorMessage}</p>}
              <form onSubmit={handleSubmit}>
                <div className='mb-4'>
                  <input
                    type="email"
                    placeholder="Twój email"
                    value={userEmail}
                    onChange={handleEmailChange}
                    className='text-xl lg:text-md w-full p-2 border-2 border-gold rounded focus:outline-none focus:border-bronze'
                  />
                </div>
                <div className='mb-1 relative'>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={userPassword}
                    onChange={handlePasswordChange}
                    placeholder="Twoje hasło"
                    className='text-xl lg:text-md w-full p-2 border-2 border-gold rounded focus:outline-none focus:border-bronze'
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className='absolute inset-y-0 right-0 pr-3 flex items-center text-bronze'
                  >
                    {showPassword ? <BsEyeSlash className='h-5 w-5' /> : <BsEye className='h-5 w-5' />}
                  </button>
                </div>
                <div className='flex items-start gap-2 mt-2 '>
                  <label htmlFor="checkbox-1" className='h-5 lg:w-4 w-5 lg:h-4 border-[3px] lg:border-[2px] border-bronze rounded flex items-center justify-center mt-1 lg:mt-0.5'>
                    <input
                      type="checkbox"
                      className='hidden'
                      id="checkbox-1"
                      onChange={() => togglePrivacy()}
                    />
                    {privacy ? (
                      <FaCheck className='text-bronze h-5 lg:w-5 w-5 lg:h-5' />
                    ) : (
                      <FaCheck className='text-transparent h-5 lg:w-5 w-5 lg:h-5' />
                    )}
                  </label>
                  <p className='text-lg lg:text-sm font-light text-white'>
                    Wyrażam zgodę na przetwarzanie moich danych osobowych przez Prorok Szkoleniowy zgodnie z
                    <Link
                      // to={'/'}
                      className='ml-1 underline cursor-pointer'>Polityką prywatności</Link>
                  </p>
                </div>
                <button
                  type="submit"
                  className='h-8 mt-4 w-max mx-auto text-lg lg:text-sm bg-bronze text-white flex items-center px-4 rounded gap-3 cursor-pointer hover:bg-gold transition ease-in-out duration-300'
                >
                  <p>Zarejestruj się</p>
                  <FaArrowRightLong />
                </button>
              </form>
            </div>
            <p className='text-white mt-4 text-lg lg:text-sm flex gap-2 justify-end font-extralight'>
              Masz już konto? <Link to="/login" className='text-bronze underline pointer'>Zaloguj się</Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUp;
