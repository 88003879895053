import React, { useEffect, useState } from 'react';
import TestChoice from './TestChoice';
import gnomek from '../../img/gnomek.png';
import dialog from '../../img/dialog.png';
import { Bars, ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { 
  fetchBasicSurveyData, 
  createEmptyProfile,
   submitSurveyResponses, 
  fetchSurveyResults,
  getBasicSurvey
 } from '../../api/api';

const BasicTest = () => {
  const [surveyData, setSurveyData] = useState({
    surveyId: 3,
    surveyType: "BASIC_SURVEY",
    surveyName: "Ankieta podstawowa",
    questions: []
  });
  const [qIndex, setQIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [surveyResultId, setSurveyResultId] = useState(null);
  const currentProgres = ((qIndex / surveyData.questions.length) * 100);
  const [questionLoading, setQuestionLoading] = useState(true);
  const navigate = useNavigate();
  const profileId = localStorage.getItem('profileId');


  // Fetch basic survey data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const idBasicSurvey = await getBasicSurvey();
        const data = await fetchBasicSurveyData(idBasicSurvey);
        setSurveyData(data);
        setQuestionLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);

  // Fetch survey results based on surveyResultId
  useEffect(() => {
    if (surveyResultId) {
      const fetchResults = async () => {
        try {
          await fetchSurveyResults(surveyResultId);
        } catch (err) {
          console.log(err.message);
        }
      };

      fetchResults();
    }
  }, [surveyResultId]);

  // Next test question
  const nextQuestion = () => {
    setQuestionLoading(true);
    setQIndex(qIndex + 1);
    setTimeout(() => {
      setQuestionLoading(false);
    }, 1000);
  }

  // Previous test question
  const prevQuestion = () => {
    if (qIndex > 0) {
      setQuestionLoading(true);
      setQIndex(qIndex - 1);
      setTimeout(() => {
        setQuestionLoading(false);
      }, 1000);
    }
  };

  // Submit test
  const submitTest = async () => {
    if (answers.length === surveyData.questions.length) {
      try {
        const profileData = await createEmptyProfile();
        const profileIdN = profileId ? profileId : profileData.id;

        const surveyResponseData = await submitSurveyResponses(surveyData.surveyId, profileIdN, answers);
        const surveyResultId = surveyResponseData.surveyResultId; // Assuming the API returns surveyResultId
        setSurveyResultId(surveyResultId);

        // Fetch the survey result data immediately after getting the surveyResultId
        const surveyResultData = await fetchSurveyResults(surveyResultId);

        // Navigate to the results page, passing the surveyResultId
        navigate(`/basic-test-result/${surveyResultData.singleResult}/${profileIdN}`);
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  // Save answer of basic test
  const saveAnswer = (questionId, answer) => {
    setAnswers(prevAnswers => {
      const updatedAnswers = prevAnswers.filter(a => a.questionId !== questionId);
      return [...updatedAnswers, { questionId, answer }];
    });
  };

  return (
    <div className='w-full full-height-minus-5rem bg-lightBeige flex flex-col items-center justify-start overflow-hidden'>
      <div className='w-[90vw] lg:w-[1000px]'>

        {/* Header */}
        <h1 className='text-2xl md:text-4xl my-2 md:my-6'>
          Na początek pytania <span className='text-bronze font-semibold'>wstępne</span>!
        </h1>

        {/* Progres bar */}
        <div className='w-full h-[15px] md:h-[20px] border-2 border-bronze rounded-xl my-1 md:my-4'>
          <div
            className="rounded-xl bg-maroon h-full transition-all duration-500"
            style={{ width: `${currentProgres}%` }}
          >
          </div>
        </div>


        <div className='w-full lg:w-4/5 h-[350px]'>
          {surveyData.questions.length > 0 ? (
            <>
              {questionLoading ? (
                <div className='w-full h-3/5 flex items-center justify-center'>

                  {/* all questions loader */}
                  <ThreeDots
                    visible={true}
                    height="150"
                    width="150"
                    color="#812329"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (

                // Single test block
                <div>
                  <TestChoice
                    qIndex={qIndex}
                    qData={surveyData.questions[qIndex]}
                    nextQuestion={nextQuestion}
                    prevQuestion={prevQuestion}
                    saveAnswer={saveAnswer}
                    submitTest={submitTest}
                    lastQuestion={qIndex === surveyData.questions.length - 1}
                    currentAnswer={answers.find(a => a.questionId === surveyData.questions[qIndex].questionId)?.answer}
                  />
                </div>
              )}
            </>
          ) : (

            // All questions loader
            <div className='w-full h-full flex items-center justify-center'>
              <Bars
                height="250"
                width="250"
                color="#812329"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
        </div>
      </div>

      {/* Gnomek block */}
      <div className='h-full w-2/5 relative hidden sm:block'>
        <div className='w-[70%]'>
          <img
            src={dialog}
            alt="dialog"
            className='absolute left-[-280px] bottom-[140px]'
          />
          <p className='absolute left-[-240px] bottom-[220px] text-lg font-medium text-center'>
            Do dzieła!<br />
            <div className='text-sm flex text-center leading-5 mt-1'>Teraz musisz powiedzieć<br /> mi coś o sobie.</div>
          </p>
        </div>
        <img
          src={gnomek}
          alt="gnomek"
          className='absolute w-72 left-[-70px] z-10 bottom-[-75px]'
          style={{ transform: 'rotateY(180deg)' }}
        />
      </div>
    </div>
  )
}

export default BasicTest;
