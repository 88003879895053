const filterOptions = {
  formOfKnowledge: [
    { name: "Aplikacja", id: 1 },
    { name: "Do określenia", id: 2 },
    { name: "Indywidualne szkolenia", id: 3 },
    { name: "Indywidualne wsparcie", id: 4 },
    { name: "Materiały fizyczne", id: 5 },
    { name: "Materiały online", id: 6 },
    { name: "Social learning - wymiana wiedzy między uczestnikami", id: 7 },
    { name: "Stacjonarne ćwiczenia grupowe", id: 8 },
    { name: "Webinary", id: 9 },
    { name: "Wyjścia przyrodnicze", id: 12 },
    { name: "Wykłady", id: 10 },
    { name: "Ćwiczenia grupowe online", id: 11 }
  ],
  trainingMode: [
    { name: "Hybrydowo", id: 1 },
    { name: "On-line", id: 2 },
    { name: "Produkt fizyczny", id: 3 },
    { name: "Stacjonarnie", id: 4 }
  ],
  typeOfContent: [
    { name: "Aplikacja", id: 183 },
    { name: "Artykuły", id: 197 },
    { name: "Audiobooki", id: 189 },
    { name: "Audiowizualne", id: 188 },
    { name: "Audyt", id: 199 },
    { name: "Booktube", id: 190 },
    { name: "Ebooki", id: 191 },
    { name: "Filmy YouTube", id: 192 },
    { name: "Interaktywne", id: 182 },
    { name: "Konsultacje stacjonarne", id: 200 },
    { name: "Książki", id: 195 },
    { name: "Kursy Interaktywne", id: 184 },
    { name: "Live", id: 185 },
    { name: "Mentoring", id: 201 },
    { name: "Newslettery", id: 196 },
    { name: "Podcasty", id: 193 },
    { name: "Poradniki", id: 186 },
    { name: "Tekstowe", id: 194 },
    { name: "Warsztaty", id: 202 },
    { name: "Webinary", id: 203 },
    { name: "Wykładowe", id: 187 },
    { name: "Z człowiekiem", id: 198 }
  ],
  contentCategory: [
    { name: "Analiza prawna", id: 194 },
    { name: "Bezpieczeństwo", id: 191 },
    { name: "Biznes", id: 167 },
    { name: "Branding", id: 168 },
    { name: "Budowanie relacji", id: 202 },
    { name: "Budowanie strategii", id: 184 },
    { name: "Budowanie wiarygodności firm", id: 169 },
    { name: "Case study", id: 190 },
    { name: "Coaching", id: 205 },
    { name: "Cukrzyca", id: 144 },
    { name: "Dieta i odżywianie", id: 145 },
    { name: "Diety niskowęglowodanowe", id: 146 },
    { name: "Edukacja", id: 170 },
    { name: "Edukacja prawna", id: 159 },
    { name: "Efektywność", id: 204 },
    { name: "Emerytura", id: 185 },
    { name: "Emocje", id: 199 },
    { name: "Finanse", id: 135 },
    { name: "Giełda", id: 136 },
    { name: "Giełda metali", id: 137 },
    { name: "HR", id: 171 },
    { name: "Hipoteka", id: 138 },
    { name: "Insulinooporność", id: 148 },
    { name: "Inwestowanie", id: 139 },
    { name: "Inwestycje", id: 182 },
    { name: "Komunikacja", id: 196 },
    { name: "Kosmetyki", id: 149 },
    { name: "Kredyt", id: 140 },
    { name: "Kredyty", id: 183 },
    { name: "Księgowość", id: 195 },
    { name: "Kuchnia", id: 150 },
    { name: "Kulinaria", id: 151 },
    { name: "Marketing", id: 172 },
    { name: "Materiały edukacyjne", id: 181 },
    { name: "Medycyna", id: 152 },
    { name: "Mentoring", id: 173 },
    { name: "Motywacja", id: 208 },
    { name: "Nauka", id: 163 },
    { name: "Nawyki", id: 209 },
    { name: "Nawyki żywieniowe", id: 153 },
    { name: "Networking", id: 174 },
    { name: "Oszczędzanie", id: 141 },
    { name: "Plany dietetyczne i treningowe", id: 154 },
    { name: "Podatki", id: 186 },
    { name: "Prawo", id: 158 },
    { name: "Przepisy", id: 160 },
    { name: "Psychologia", id: 198 },
    { name: "RODO", id: 192 },
    { name: "Relacje społeczne", id: 180 },
    { name: "Rodzina", id: 197 },
    { name: "Rozwój duchowy", id: 210 },
    { name: "Rozwój osobisty", id: 166 },
    { name: "Rozwój własnej działalności", id: 164 },
    { name: "Samopoczucie", id: 155 },
    { name: "Sprzedaż książek", id: 142 },
    { name: "Strategie", id: 161 },
    { name: "Styl życia", id: 175 },
    { name: "Suplementacja", id: 188 },
    { name: "Suplementy diety", id: 156 },
    { name: "Szkoła wyższa", id: 193 },
    { name: "Terapia", id: 203 },
    { name: "Trening", id: 189 },
    { name: "Treningi", id: 157 },
    { name: "Tworzenie kampanii reklamowych", id: 176 },
    { name: "Tworzenie kanału na YT", id: 177 },
    { name: "Tworzenie stron internetowych", id: 178 },
    { name: "Umiejętności miękkie", id: 207 },
    { name: "Wsparcie techniczne i doradztwo", id: 162 },
    { name: "Wsparcie w prowadzeniu kanału", id: 179 },
    { name: "Wychowanie", id: 200 },
    { name: "Zarządzanie", id: 165 },
    { name: "Zarządzanie stresem", id: 206 },
    { name: "Zdrowie", id: 143 },
    { name: "Zdrowie i fitness", id: 147 },
    { name: "Zdrowie psychiczne", id: 187 },
    { name: "Związki", id: 201 }
  ]
};

export default filterOptions;
