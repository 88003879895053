import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BackendClient } from '../../api/api';
import { TbSquareRoundedChevronRight } from "react-icons/tb";
import { Link } from 'react-router-dom';

const getStatusLayout = (type) => {
  if (type === "MATCHED") {
    return (
      <div className='text-xs py-0.5 px-1.5 text-white bg-[#D1BA5C] rounded'>
        przypisane
      </div>
    )
  }
  if (type === "IN_PROGRESS") {
    return (
      <div className='text-xs py-0.5 px-1.5 text-white bg-[#91B3FA] rounded'>
        w trakcie
      </div>
    )
  }
  if (type === "FOR_LATER") {
    return (
      <div className='text-xs py-0.5 px-1.5 text-white bg-[#ff8533] rounded'>
        na później
      </div>
    )
  }
  if (type === "COMPLETED") {
    return (
      <div className='text-xs py-0.5 px-1.5 text-white bg-[#60B271] rounded'>
        zakończone
      </div>
    )
  }
}


const Progress = ({ handleOpenReview, handleOpenFinishCourse }) => {
  const backendClient = useMemo(() => new BackendClient(), []);
  const userId = localStorage.getItem('userId');
  const [courses, setCourses] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);


  const getLayoutAction = (type, courseIdNum, courseName) => {
    if (type === "MATCHED") {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            changeCourse(userId, courseIdNum, "IN_PROGRESS");
          }}
          className='text-xs py-1 px-2 text-white bg-maroon rounded flex items-center gap-0.5'>
          <div>
            Rozpocznij
          </div>
          <TbSquareRoundedChevronRight className='w-4 h-4' />
        </div>
      )
    }
    if (type === "IN_PROGRESS") {
      return (
        <div className='flex flex-col gap-1'>
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleOpenFinishCourse(courseIdNum);
            }}
            className='text-xs py-1 px-2 text-white bg-maroon rounded flex items-center gap-0.5'>
            <div>
              Zakończ
            </div>
            <TbSquareRoundedChevronRight className='w-4 h-4' />
          </div>
          <div
            className='text-xs py-1 px-2 text-white bg-maroon rounded flex items-center gap-0.5'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleOpenReview(courseIdNum, courseName);
            }}
          >
            <div>
              Opinia
            </div>
            <TbSquareRoundedChevronRight className='w-4 h-4' />
          </div>
        </div>
      )
    }
    if (type === "FOR_LATER") {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            changeCourse(userId, courseIdNum, "IN_PROGRESS");
          }}
          className='text-xs py-1 px-2 text-white bg-maroon rounded flex items-center gap-0.5'>
          <div>
            Rozpocznij
          </div>
          <TbSquareRoundedChevronRight className='w-4 h-4' />
        </div>
      )
    }
    if (type === "COMPLETED") {
      return (
        <div
          className='text-xs py-1 px-2 text-white bg-maroon rounded flex items-center gap-0.5'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleOpenReview(courseIdNum, courseName);
          }}
        >
          <div>
            Opinia
          </div>
          <TbSquareRoundedChevronRight className='w-4 h-4' />
        </div>
      )
    }
  }
  const getBasicSurvey = useCallback(async () => {
    try {
      const response = await backendClient.getCoursesMatchedAll(userId);
      setCourses(response[0].courses);
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, userId,]);

  const changeCourse = useCallback(async (userId, courseIdNum, status) => {
    try {
      const response = await backendClient.changeCourseStatus(userId, courseIdNum, status);
      console.log(response);
      getBasicSurvey();
    } catch (error) {
      console.log(error);
    }
  }, [backendClient, getBasicSurvey]);

  useEffect(() => {
    getBasicSurvey();
  }, [getBasicSurvey])
  return (
    <div>
      <div>
        <p className='font-semibold'>Moje postępy</p>
        <p className='font-light text-sm mt-1'>W tej zakładce znajdują się wszystkie Twoje kursy o różnych statusach. Możesz je zakończyć, wystawiać do nich opinię lub oznaczać jako “kursy w trakcie nauki”.</p>
      </div>

      <div className="w-full flex items-center py-1 mt-2 bg-[#C29E7366] text-xs md:text-sm font-light">
        <p className='w-[5%] flex justify-center'>LP</p>
        <p className='w-[17%] flex justify-center'>Miniatura</p>
        <p className='w-[17%] flex justify-center'>Dział</p>
        <p className='w-[28%] flex justify-center'>Tytuł</p>
        <p className='w-[8%] flex justify-center'>Cena</p>
        <p className='w-[13%] flex justify-center'>Status</p>
        <p className='w-[14%] flex justify-center'>Akcje</p>
      </div>

      <div className='mt-4'>
        {courses.map((item, index) => (
          <Link
            to={`/course/${item.id}`}
            className="w-full flex items-center py-1 mt-2 animateSl hover:bg-[#C29E7366] text-xs font-light rounded"
            key={item.id}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <p className='w-[5%] flex justify-center'>{index + 1}</p>
            <p className='w-[17%] flex justify-center'>
              <img
                src={`https://images.weserv.nl/?url=https://api.prorokszkoleniowy.pl/images/${item.imageId}`}
                alt={item.imageId}
                className='w-full h-24 rounded object-cover object-center'
              />
            </p>
            <p className='w-[17%] flex justify-center'>{item.contentCategory.length > 0 ? item.contentCategory[0].name : ''}</p>
            <p className='w-[28%] flex justify-center'>{item.name}</p>
            <p className='w-[8%] flex justify-center'>{item.minPrice}</p>
            <p className='w-[13%] flex justify-center'>{getStatusLayout(item.status)}</p>
            <p className='w-[14%] flex justify-center'>
              <div
                className={`${hoveredIndex === index ? 'opacity-100' : 'opacity-0'
                  } transition-all duration-300 ease-in-out z-40`}
              >
                {getLayoutAction(item.status, item.id, item.name)}
              </div>
            </p>
          </Link>
        ))}
      </div>

    </div>
  )
}

export default Progress
