import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Filters from './Filters'
import CourseBox from './CourseBox'
import { BackendClient } from '../../api/api';
import Offer from './Offer';
import { ThreeDots } from 'react-loader-spinner';


const CourseList = () => {
  const backendClient = useMemo(() => new BackendClient(), []);
  const [courses, setCourses] = useState([]);
  const [querySearch, setQuerySearch] = useState('');
  const [queryText, setQueryText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleQuery = (str) => {
    if (str && str.trim() !== "") {
      setQueryText('');
      setQuerySearch(str);
    }
  };

  const handleQueryText = (str) => {
    if (str && str.trim() !== "") {
      setQuerySearch('');
      setQueryText(str);

      setTimeout(() => {
        const element = document.getElementById('elementToScroll');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }

  const isScreenBiggerThan1024px = () => {
    return window.matchMedia('(min-width: 1025px)').matches;
  };

  const getCourses = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await backendClient.getAllCourses(querySearch, queryText);
      setCourses(response);
      setIsLoading(false);
    } catch (error) {
      console.log('Error fetching courses:', error);
      setCourses([]);
    }
  }, [backendClient, querySearch, queryText]);

  useEffect(() => {
    getCourses();
  }, [getCourses])

  return (
    <div>
      <Offer handleQuery={handleQueryText} />

      <div className='h-min bg-lightBeige py-8 flex justify-center' id='elementToScroll'>
        <div className='w-full lg:w-[1250px] flex flex-col md:flex-row relative px-4 lg:px-0 relative'>
          <div className='w-full md:w-2/5 lg:w-1/4 h-full md:sticky top-6'>
            <Filters handleQuery={handleQuery} />
          </div>
          <div className='w-full md:w-3/5 lg:w-[70%] h-min md:ml-10'>
            <div className='w-1/5 border-t-2 border-bronze'></div>
            <h2 className='text-4xl mt-4'>Dostępne <span className='text-bronze'>kursy!</span></h2>

            {isLoading ? (
              <div className='w-full flex items-center justify-center'>
                <ThreeDots
                  visible={true}
                  height="250"
                  width="250"
                  color="#812329"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <>
                {courses.length > 0 && (
                  <div className='w-full flex flex-wrap'>
                    {courses.map(course => (
                      <CourseBox course={course} isCompact={true} isSmall={!isScreenBiggerThan1024px()} />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseList
